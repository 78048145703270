// ============================================================================
// Module: Buttons
// ============================================================================

// Buttons
//
// These are the different kinds of buttons used on the site. We use the same
// visual states for <code>.disabled</code> and <code>.disabled:hover</code> to
// indicate that the button can't be interacted with.
//
// :hover           - hover state
// :focus           - focus state
// :active          - active state
// .disabled        - disabled state
// .disabled:hover  - disabled hover state
//
// Markup:
// <button class="button {{modifier_class}}">Label</button>
// <button class="button button--primary {{modifier_class}}">Primary</button>
// <button class="button button--secondary {{modifier_class}}">Secondary</button>
// <button class="button button--submit {{modifier_class}}">Submit</button>
//
// Styleguide 4.1

.button {
    border: 2px solid $color-2;
    background: #fff;
    color: #000;
    padding: 10px 28px 10px 28px;
    cursor: pointer;
    -webkit-appearance: none;
    font-family: $font-base;
    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 20px;
    text-decoration: none;
    white-space: nowrap;
    transition: all 0.3s;
    line-height: 1.5;

    &:hover {
        background-color: $color-2;
        border: 2px solid $color-2;
        color: #fff;
    }

    &:active {
        background-color: lighten($color-2, 5);
        border: 2px solid $color-2;
        color: #fff;
    }

    &:focus {
        @include outline();
    }

    &.disabled,
    &.disabled:hover {
        background: #eee;
        border-color: #eee;
        color: #aaa;

        .icon {
            opacity: 0.5;
        }
    }

    .icon {
        float: left;
        display: inline-block;
        width: 24px;
        height: 24px;
        background-size: 100%;
        margin: -2px 7px 0 -4px;
    }

    /* Primary action button, i.e. 'Ok' or 'Send' */
    &--primary,
    &--submit {
        background: linear-gradient(90deg, $color-2, $color-3);
        padding: 12px 40px 12px 40px;
        border: 0;
        color: #fff;
        min-width: 80px;
        text-align: center;
        transition: 0.5s all;

        &:hover {
            background: linear-gradient(90deg, darken($color-2, 5), darken($color-3, 5));
            color: $color-1-text;
            border: 0;
        }

        &:active {
            @include outline();

            background: lighten($color-1, 3);
            color: $color-1-text;
            border: 0;
        }

        &.disabled,
        &.disabled:hover {
            text-shadow: none;
        }
    }

    /* Secondary action button, i.e. 'Cancel' */
    &--secondary {
        border: 2px solid $color-4;
        background: $color-4;
        color: #fff;

        &:hover {
            border-color: lighten($color-4, 5);
            background: lighten($color-4, 5);
            color: #fff;
        }

        &:active {
            border-color: lighten($color-4, 10);
            background: lighten($color-4, 10);
            color: #fff;
        }

        &.disabled,
        &.disabled:hover {
            background: lighten($color-4, 5%);
            border-color: lighten($color-4, 5%);
            color: lighten($color-4, 25%);
        }
    }

    /* White on transparent */
    &--white {
        border: 2px solid #fff;
        background: transparent;
        color: #fff;

        &:hover {
            border: 2px solid #fff;
            background: #fff;
            color: #000;
        }

        &:active {
            border: 2px solid #fff;
            background: #fff;
            color: #000;
        }

        &.disabled,
        &.disabled:hover {
            border: 2px solid lighten(#fff, 50%);
            color: lighten(#fff, 50%);
        }
    }

    /* Black on transparent */
    &--black {
        border: 2px solid #000;
        background: transparent;
        color: #000;

        &:hover {
            border: 2px solid #000;
            background: #000;
            color: #fff;
        }

        &:active {
            border: 2px solid #000;
            background: #000;
            color: #fff;
        }

        &.disabled,
        &.disabled:hover {
            border-color: lighten(#000, 50%);
            color: lighten(#000, 50%);
        }
    }

    /* Transparent */
    &--transparent {
        display: none;
        background: rgba(255, 255, 255, 0.15);
        color: #fff;
        border: 0;

        @include respond-to($tablet) {
            display: block;
        }

        &:hover {
            background: rgba(255, 255, 255, 0.25);
            color: #fff;
            border: 0;
        }
    }
}

a.button {
    display: inline-block;
}
