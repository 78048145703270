@charset "UTF-8";
/*
MOBILE (12 COL GRID):      12 + 12 x (50 + 12) +  12  =>   756 PX
TABLET (12 COL GRID):      60 + 12 x (60 + 25) +  60  =>  1115 PX
DESKTOP (12 COL GRID):    100 + 12 x (60 + 40) + 100  =>  1360 PX
WIDESCREEN (12 COL GRID):  50 + 12 x (60 + 40) +  50  =>  1260 PX
*/
html:not(.wf-active) h1 {
  font-size: 1.85em;
  letter-spacing: -1px;
  line-height: 1.55;
}
@media screen and (min-width: 600px) {
  html:not(.wf-active) h1 {
    font-size: 3.6em;
    line-height: 1.5;
  }
}

/* Adobe Fonts */
.oldstyle-numerals {
  font-feature-settings: "onum" 1, "frac" 1, "calt" 0;
  font-variant-numeric: oldstyle-nums;
}

.lining-numerals {
  font-feature-settings: "lnum" 1, "frac" 1, "calt" 0;
  font-variant-numeric: lining-nums;
}

.proportional-numerals {
  font-feature-settings: "pnum" 1, "frac" 1, "calt" 0;
  font-variant-numeric: proportional-nums;
}

.tabular-numerals {
  font-feature-settings: "tnum" 1, "frac" 0, "calt" 0;
  font-variant-numeric: tabular-nums;
}

.vertical-rhythm {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2) 1px, transparent 1px);
  background-size: 100% 1.55em;
}

.no-ligatures {
  font-feature-settings: "liga" 0;
}
@supports (font-variant-ligatures: no-common-ligatures) {
  .no-ligatures {
    font-feature-settings: normal;
    font-variant-ligatures: no-common-ligatures;
  }
}

.discretionary-ligatures {
  font-feature-settings: "dlig" 1, "hist" 1;
}
@supports (font-variant-ligatures: discretionary-ligatures historical-ligatures) {
  .discretionary-ligatures {
    font-feature-settings: normal;
    font-variant-ligatures: discretionary-ligatures historical-ligatures;
  }
}

@supports (font-variant-numeric: diagonal-fractions) {
  .diagonal-fractions {
    font-feature-settings: normal;
    font-variant-numeric: diagonal-fractions;
  }
}

p.drop-cap::first-letter {
  float: left;
  font-size: 75px;
  margin: 0 0.1em 0 0;
  padding: 0;
  line-height: 65px;
}

.caps,
.small-caps {
  font-feature-settings: "smcp" 1, "c2sc" 1;
  letter-spacing: 0.04em;
}
@supports (font-variant-caps: all-small-caps) {
  .caps,
  .small-caps {
    font-feature-settings: normal;
    font-variant-caps: all-small-caps;
  }
}

.proportional-nums {
  font-feature-settings: "pnum" 1;
}
@supports (font-variant-numeric: proportional-nums) {
  .proportional-nums {
    font-feature-settings: normal;
    font-variant-numeric: proportional-nums;
  }
}

.tabular-nums {
  font-feature-settings: "tnum" 1, "zero" 1;
}
@supports (font-variant-numeric: tabular-nums) {
  .tabular-nums {
    font-feature-settings: normal;
    font-variant-numeric: tabular-nums slashed-zero;
  }
}

.oldstyle-nums {
  font-feature-settings: "onum" 1;
}
@supports (font-variant-numeric: oldstyle-nums) {
  .oldstyle-nums {
    font-feature-settings: normal;
    font-variant-numeric: oldstyle-nums;
  }
}

.lining-nums {
  font-feature-settings: "lnum" 1;
}
@supports (font-variant-numeric: lining-nums) {
  .lining-nums {
    font-feature-settings: normal;
    font-variant-numeric: lining-nums;
  }
}

.lining-nums-with-fractions {
  font-feature-settings: "lnum" 1, "frac" 1;
}
@supports (font-variant-numeric: lining-nums) and (font-variant-numeric: diagonal-fractions) {
  .lining-nums-with-fractions {
    font-feature-settings: normal;
    font-variant-numeric: lining-nums diagonal-fractions;
  }
}

.proportional-lining-nums {
  font-feature-settings: "pnum" 1, "lnum" 1;
}
@supports (font-variant-numeric: proportional-nums) {
  .proportional-lining-nums {
    font-feature-settings: normal;
    font-variant-numeric: proportional-nums lining-nums;
  }
}

.proportional-oldstyle-nums {
  font-feature-settings: "pnum" 1, "onum" 1;
}
@supports (font-variant-numeric: proportional-nums) {
  .proportional-oldstyle-nums {
    font-feature-settings: normal;
    font-variant-numeric: proportional-nums oldstyle-nums;
  }
}

.tabular-lining-nums {
  font-feature-settings: "tnum" 1, "lnum" 1;
}
@supports (font-variant-numeric: tabular-nums) {
  .tabular-lining-nums {
    font-feature-settings: normal;
    font-variant-numeric: tabular-nums lining-nums;
  }
}

.tabular-oldstyle-nums {
  font-feature-settings: "tnum" 1, "onum" 1;
}
@supports (font-variant-numeric: tabular-nums) {
  .tabular-oldstyle-nums {
    font-feature-settings: normal;
    font-variant-numeric: tabular-nums oldstyle-nums;
  }
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 17px;
  font-family: sofia-pro, "Helvetica", "Helvetica Neue", "San Francisco", sans-serif;
}
@media screen and (min-width: 600px) {
  html {
    font-size: 17px;
  }
}
@media screen and (min-width: 1024px) {
  html {
    font-size: 18px;
  }
}

body {
  background: #6f41d1;
  color: #333;
}

/* Remove ~300 ms dealy on Mobile Safari for the following elements */
a,
button,
input,
select,
textarea,
label,
summary {
  touch-action: manipulation;
}

h1,
h2 {
  font-family: mr-eaves-sans, "Helvetica", "Helvetica Neue", "San Francisco", sans-serif;
  font-weight: 200;
}

h3,
h4,
h5,
h6 {
  font-family: mr-eaves-sans, "Helvetica", "Helvetica Neue", "San Francisco", sans-serif;
  font-weight: 700;
}

p,
ul,
li {
  font-weight: 400;
}

p,
ul,
ol,
li,
h1,
h2,
h3,
h4 {
  margin: 0 0 1.45em 0;
  padding: 0;
  font-size: 1em;
  line-height: 1.45;
}
@media screen and (min-width: 768px) {
  p,
  ul,
  ol,
  li,
  h1,
  h2,
  h3,
  h4 {
    margin: 0 0 1em 0;
    line-height: 1.55;
  }
}

ul ul,
ol ol,
dl dl {
  margin-bottom: 0;
}

h1 {
  margin: 0 0 0.5em 0;
  font-size: 2em;
  line-height: 1;
  font-weight: 100;
  font-style: italic;
}
@media screen and (min-width: 340px) {
  h1 {
    margin: 0 0 0.75em 0;
    font-size: 2.5em;
  }
}
@media screen and (min-width: 600px) {
  h1 {
    margin: 0 0 0.75em 0;
    font-size: 5em;
  }
}

h2 {
  font-family: sofia-pro-soft, "Helvetica", "Helvetica Neue", "San Francisco", sans-serif;
  margin: 1.5em 0 0.3em 0;
  padding: 0.3em 0;
  font-size: 1.2em;
  line-height: 1.4;
}
@media screen and (min-width: 600px) {
  h2 {
    margin: 1.8em 0 0.2em 0;
    font-size: 40px;
    line-height: 1.2;
  }
}

h3 {
  font-family: sofia-pro-soft, "Helvetica", "Helvetica Neue", "San Francisco", sans-serif;
  margin: 2em 0 0.5em 0;
  font-size: 1.3em;
}

h4,
h5,
h6 {
  margin: 1em 0 0.5em 0;
  font-size: 0.9em;
  color: #666;
}

img {
  max-width: 100%;
  height: auto;
}

ul,
ol,
dl {
  margin: 0 0 1.5em 20px;
  line-height: 1.5;
}
@media screen and (min-width: 768px) {
  ul,
  ol,
  dl {
    margin: 0 0 1.5em 0;
  }
}

li {
  margin-bottom: 0;
}

dt {
  font-weight: bold;
  color: #000;
}

dd {
  margin: 0 0 1em 0;
}

a {
  color: #266c9f;
  font-weight: 600;
}
a:hover {
  color: #3088c8;
}

b,
strong {
  font-family: sofia-pro, "Helvetica", "Helvetica Neue", "San Francisco", sans-serif;
  font-weight: 800;
}

pre {
  padding: 20px;
  background: #eee;
  color: #333;
  font-size: 0.75em;
  line-height: 1.6;
  margin-bottom: 3em;
}

sup {
  font-feature-settings: "sups" 1;
}
@supports (font-variant-position: super) {
  sup {
    font-feature-settings: normal;
    font-variant-position: super;
  }
}
@supports (font-variant-position: super) or (font-feature-settings: "sups" 1) {
  sup {
    vertical-align: inherit;
    font-size: inherit;
  }
}

sub {
  font-feature-settings: "subs" 1;
}
@supports (font-variant-position: sub) {
  sub {
    font-feature-settings: normal;
    font-variant-position: sub;
  }
}
@supports (font-variant-position: sub) or (font-feature-settings: "subs" 1) {
  sub {
    vertical-align: inherit;
    font-size: inherit;
  }
}

table {
  margin: 1.5em 0;
  border-collapse: collapse;
  caption-side: bottom;
}
table .number {
  text-align: right;
}
table .alignment {
  text-align: "." center;
}

caption {
  padding: 0.5em 5px 1.5em 5px;
  color: #666;
  font-size: 0.8em;
  font-style: italic;
}

th {
  font-variant-numeric: lining-nums tabular-nums;
  font-weight: bold;
  text-align: left;
  padding: 0.25em 2em 0.25em 0.5em;
  font-size: 0.8em;
  color: #333;
  border-bottom: 1px solid #000;
}
@media screen and (min-width: 768px) {
  th {
    font-size: 1em;
    padding: 0.25em 2em 0.25em 0.5em;
  }
}
th:last-child {
  padding-right: 0.5em;
}

tfoot th {
  text-align: left;
}

td {
  font-variant-numeric: lining-nums tabular-nums;
  text-align: left;
  padding: 0.25em 2em 0.25em 0.5em;
  font-size: 0.8em;
  color: #333;
  border: 0;
}
tr.no-border td {
  border: 0;
}
@media screen and (min-width: 768px) {
  td {
    font-size: 1em;
    line-height: 1.3;
    padding: 0.25em 2em 0.25em 0.5em;
  }
}
@media screen and (min-width: 1024px) {
  td {
    font-size: 0.9em;
  }
}
td:last-child {
  padding-right: 0.5em;
}

hr {
  margin: 1em 0;
  border: 0;
  border-top: 1px solid #eee;
}
@media screen and (min-width: 600px) {
  hr {
    margin: 4em 0 3em 0;
  }
}

.hr hr {
  display: none;
}

.hr {
  clear: both;
  background: linear-gradient(156deg, #6f41d1 0%, #4194d1 53%, #41d1c0 100%);
  height: 3px;
  width: 50%;
  margin: 1em auto;
}
@media screen and (min-width: 600px) {
  .hr {
    width: 30%;
    margin: 6em auto 1em auto;
  }
}

blockquote {
  margin: 2em 0 2em 25px;
  color: #333;
  font-style: italic;
}
@media screen and (min-width: 768px) {
  blockquote {
    margin: 0 0 1em 50px;
    padding: 0 20px;
  }
}
blockquote p {
  quotes: "“" "”" "‘" "’";
}
blockquote p::before {
  content: open-quote;
  margin-left: -0.83ch;
}
blockquote p::after {
  content: close-quote;
}

figure {
  margin: 0;
}

figcaption {
  color: #666;
  font-size: 0.8em;
  font-style: italic;
  margin: 0.5em 0 2em 0;
}

object {
  outline: none;
}

.small {
  float: right;
  margin-right: 20px;
  font-size: 11px;
}

.center {
  text-align: center;
}

picture.right {
  float: right;
  margin: 0 0 2em 4em;
}

picture.left {
  float: left;
  margin: 0 2em 4em 0;
}

fieldset {
  margin: 0 0 3em 0;
  padding: 1em;
  border: 1px solid #333;
}
@media screen and (min-width: 600px) {
  fieldset {
    padding: 1.5em;
  }
}

legend {
  padding: 0 2px;
  color: #333;
  font-family: sofia-pro-soft, "Helvetica", "Helvetica Neue", "San Francisco", sans-serif;
  font-weight: normal;
}

label {
  font-family: sofia-pro-soft, "Helvetica", "Helvetica Neue", "San Francisco", sans-serif;
  font-weight: normal;
  display: block;
  margin: 13px 0 5px 0;
  font-size: 0.85em;
}
label.long {
  margin: 15px 0;
  line-height: 0.9;
}

input {
  font-family: sofia-pro, "Helvetica", "Helvetica Neue", "San Francisco", sans-serif;
  font-weight: normal;
  color: #333;
  font-size: 16px;
  border-radius: 3px;
}
input[type=text], input[type=email], input[type=password], input[type=number], input[type=tel], input[type=url], input[type=search] {
  -webkit-appearance: none;
  box-sizing: border-box;
  margin: 0;
  padding: 7px;
  border: 1px solid #ddd;
  width: 100%;
  background-color: #fff;
}
input[type=number] {
  text-align: center;
  padding: 5px 3px;
  width: 45px;
}
@media screen and (min-width: 768px) {
  input[type=number] {
    padding: 5px;
    width: 60px;
  }
}
input[type=color] {
  padding: 0;
}
input[type=file] {
  font-size: 12px;
}
input[readonly=readonly] {
  color: #ccc;
}
input[disabled] {
  background: #eee;
  color: #333;
}
input:focus:not([readonly]) {
  outline: none;
  border: 1px solid rgba(66, 153, 208, 0.6);
}
input.submit {
  margin: 25px 0 10px 0;
}

textarea {
  font-family: sofia-pro, "Helvetica", "Helvetica Neue", "San Francisco", sans-serif;
  box-sizing: border-box;
  width: 100%;
  height: 100px;
  padding: 5px;
  border: 1px solid #f5f5f5;
  font-size: 16px;
}
@media screen and (min-width: 600px) {
  textarea {
    max-width: 600px;
  }
}
textarea:focus {
  outline: none;
  border: 1px solid rgba(66, 153, 208, 0.6);
}
select {
  display: block;
  font-size: 16px;
  font-family: sans-serif;
  font-weight: 700;
  color: #444;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 0.5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"), linear-gradient(to bottom, #fff 0%, #e5e5e5 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}
select::-ms-expand {
  display: none;
}
select:hover {
  border-color: #888;
}
select:focus {
  border-color: #aaa;
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
  color: #222;
  outline: none;
}
select option {
  font-weight: normal;
}

::-webkit-input-placeholder {
  font-size: 16px;
  color: #888;
}

:-moz-placeholder { /* Firefox 18- */
  color: #888;
}

::-moz-placeholder { /* Firefox 19+ */
  color: #888;
}

:-ms-input-placeholder {
  color: #888;
}

.video-container {
  position: relative;
  overflow: hidden;
  height: 0;
  padding-bottom: 56.25%;
  margin: 1em 0;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  background: #fff;
  background-size: cover;
}
body.calendar-view {
  background: #6f41d1 url(/assets/images/structure/bg-calendar.jpg) no-repeat center top;
}
body.grid::after {
  border-top: 10px solid #DADE0A;
  content: "";
  position: fixed;
  top: 0;
  z-index: 10000;
  height: 100vh;
  width: 100%;
  background: url(/assets/images/structure/grid-mobile.png) repeat-y center top;
  background-size: 100%;
}
@media screen and (min-width: 600px) {
  body.grid::after {
    border-top: 10px solid #F4C90E;
    content: "large-phone";
  }
}
@media screen and (min-width: 768px) {
  body.grid::after {
    background: url(/assets/images/structure/grid-tablet.png) repeat-y center top;
    background-size: 100%;
    border-top: 10px solid #DB8710;
    content: "tablet";
  }
}
@media screen and (min-width: 1024px) {
  body.grid::after {
    background: url(/assets/images/structure/grid-desktop.png) repeat-y center top;
    background-size: 100%;
    border-top: 10px solid #CF000E;
    content: "desktop";
  }
}
@media screen and (min-width: 1300px) {
  body.grid::after {
    background: url(/assets/images/structure/grid-widescreen.png) repeat-y center top;
    background-size: 100%;
    border-top: 10px solid #954A11;
    max-width: 1260px;
    left: 50%;
    margin-left: -630px;
    content: "widescreen";
  }
}

.skip-to-main-content[href="#main"] {
  background: rgba(255, 255, 255, 0.9);
  color: #000;
  position: absolute;
  top: 10px;
  right: 100%;
  padding: 10px;
  margin: 5px;
}
.skip-to-main-content[href="#main"]:focus {
  right: auto;
}

[hidden] {
  display: none;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
}

.validation-messages {
  margin: 50px 0;
  padding: 20px;
}
.validation-messages.hide {
  display: none;
}
.validation-messages .alert-error {
  background: rgba(255, 0, 0, 0.6);
  color: #fff;
}
.validation-messages p {
  margin: 0;
}

.hidden-on-mobile {
  display: none;
}
@media screen and (min-width: 768px) {
  .hidden-on-mobile {
    display: inline;
  }
}

.title-long {
  display: none;
}
@media screen and (min-width: 768px) {
  .title-long {
    display: inline;
  }
}

@media screen and (min-width: 768px) {
  .title-short {
    display: none;
  }
}

.color-1 {
  background: #6f41d1;
  color: #fff;
}

.color-2 {
  background: #4194d1;
  color: #fff;
}

.color-3 {
  background: #41d1c0;
  color: #fff;
}

.color-4 {
  background: #000;
  color: #fff;
}

.color-5 {
  background: #000;
  color: #fff;
}

.color-6 {
  background: #000;
  color: #fff;
}

header .fixed-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  position: fixed;
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  box-shadow: 0px 4px 8px rgba(66, 153, 208, 0.6);
  background: linear-gradient(156deg, #6f41d1 0%, #4194d1 53%, #41d1c0 100%);
}
header .logo {
  display: block;
  position: relative;
  top: 2px;
  width: 65px;
  height: auto;
  padding: 5px;
  line-height: 0;
  margin: 0;
}
@media screen and (min-width: 340px) {
  header .logo {
    top: 3px;
    width: 110px;
  }
}
@media screen and (min-width: 768px) {
  header .logo {
    top: 5px;
    padding: 10px 10px 10px 40px;
    width: 180px;
  }
}
header nav {
  margin: 0;
}
@media screen and (min-width: 768px) {
  header nav {
    margin: 0 30px 0 0;
  }
}
header ul {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
header ul li {
  padding: 5px 8px;
}
@media screen and (min-width: 768px) {
  header ul li {
    padding: 5px 18px;
  }
}
header ul a {
  display: block;
  white-space: nowrap;
  font-family: sofia-pro, "Helvetica", "Helvetica Neue", "San Francisco", sans-serif;
  font-size: 0.7em;
  color: #fff;
  text-decoration: none;
  transition: 0.5s all;
}
@media screen and (min-width: 768px) {
  header ul a {
    font-size: 0.9em;
  }
}
header ul a:hover {
  color: rgba(255, 255, 255, 0.7);
}
header ul .home {
  display: none;
}
@media screen and (min-width: 1024px) {
  header ul .home {
    display: inline-block;
  }
}
header ul .current a {
  border-bottom: 1px solid #fff;
}
header ul .button {
  display: none;
  background: rgba(255, 255, 255, 0.15);
  color: #fff;
  border: 0;
}
@media screen and (min-width: 768px) {
  header ul .button {
    display: block;
  }
}
header ul .button:hover {
  background: rgba(255, 255, 255, 0.25);
  color: #fff;
  border: 0;
}
header ul .current a.button {
  border: 0;
  background: rgba(65, 148, 209, 0.4);
  color: #fff;
}

.breadcrumb {
  text-decoration: none;
  color: #eee;
  font-family: sofia-pro, sans-serif;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 10px;
  margin-bottom: 3em;
  border-bottom: 1px solid #eee;
}
.breadcrumb a {
  text-decoration: none;
  color: #6f41d1;
  font-family: sofia-pro, sans-serif;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
}

.main {
  box-sizing: border-box;
  margin: 0 auto;
  padding: 70px 20px 10px 20px;
  background: #fff;
  color: #000;
}
@media screen and (min-width: 768px) {
  .main {
    padding: 70px 50px 100px 50px;
  }
}
@media screen and (min-width: 1024px) {
  .main {
    padding: 70px 20px 120px 20px;
  }
}
.main .header-box .header-create-account {
  display: flex;
}

.contents {
  max-width: 900px;
  margin: 20px auto 50px auto;
}
@media screen and (min-width: 768px) {
  .contents {
    margin: 50px auto;
  }
}
.contents .main-lead,
.contents .introduction {
  font-size: 1.15em;
}
.contents .main-lead p,
.contents .introduction p {
  line-height: 1.4;
}
@media screen and (min-width: 768px) {
  .contents .main-lead,
  .contents .introduction {
    font-size: 1.5em;
    margin-bottom: 2em;
  }
  .contents .main-lead p,
  .contents .introduction p {
    line-height: 1.55;
  }
}
.contents .button-wrapper {
  margin: 1em 0;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .contents .button-wrapper {
    margin: 2em 0;
    text-align: left;
  }
}
.contents .button-wrapper .button,
.contents .button-wrapper img {
  margin: 0.5em 20px 0.5em 0;
}
.contents .button-wrapper-centered .button,
.contents .button-wrapper-centered img {
  margin: 0.5em 10px;
}

.public-contents {
  width: 90%;
  max-width: 1200px;
  margin: 20px auto;
}
@media screen and (min-width: 768px) {
  .public-contents {
    margin: 50px auto;
  }
}
.public-contents .login {
  max-width: initial;
}
.public-contents h1 {
  margin-bottom: 40px;
}
@media screen and (min-width: 600px) {
  .public-contents .form-field-group.users {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }
}
@media screen and (min-width: 768px) {
  .public-contents .form-field-group.users {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (min-width: 1024px) {
  .public-contents .form-field-group.users {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
.public-contents .form-field-group.users label {
  background-color: #f5f5f5;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
}
@media screen and (min-width: 600px) {
  .public-contents .form-field-group.users label {
    width: 45%;
    float: left;
    margin: 20px 2.5%;
    min-height: 100px;
    text-align: center;
  }
  @supports (display: grid) {
    .public-contents .form-field-group.users label {
      float: none;
      width: initial;
      margin: 0;
    }
  }
}
@media screen and (min-width: 768px) {
  .public-contents .form-field-group.users label {
    width: 22%;
    margin: 20px 1.5%;
  }
  @supports (display: grid) {
    .public-contents .form-field-group.users label {
      width: initial;
      margin: 0;
    }
  }
}
.public-contents .form-field-group.users label:hover {
  background-image: linear-gradient(156deg, #7db2d8 0%, #4194d1 53%, #3080ba 100%);
  color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}
.public-contents .form-field-group.users label input {
  display: none;
}
.public-contents .overlay {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}
.public-contents .overlay .overlay-content {
  position: fixed;
  width: 80%;
  left: 10%;
  top: 50%;
  margin-top: -100px;
  box-sizing: border-box;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #f5f5f5;
}
@media screen and (min-width: 600px) {
  .public-contents .overlay .overlay-content {
    top: 50%;
    left: 50%;
    width: 400px;
    max-width: 80%;
    margin-left: -200px;
  }
}
.public-contents .overlay .overlay-content .close {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}
.public-contents .overlay .overlay-content .close:after {
  content: "×";
  padding: 10px;
  font-size: 26px;
}
.public-contents .overlay .overlay-content label {
  font-weight: bold;
}
.public-contents .overlay .overlay-content input[type=text],
.public-contents .overlay .overlay-content input[type=password] {
  font-size: 2em;
  line-height: 1;
  padding: 10px;
}
.public-contents .overlay .overlay-content .password-toggle-button {
  background: #eee;
  right: 5px !important;
  top: 15px !important;
}
.public-contents .overlay .overlay-content .button {
  margin: 20px auto 15px auto;
}
.public-contents .alert-error {
  background: #f8e7e2;
  padding: 25px 45px;
  margin-bottom: 30px;
}

.usp {
  background: #6f41d1;
  background: linear-gradient(156deg, #6f41d1 0%, #4194d1 53%, #41d1c0 100%);
  color: #fff;
  margin: 20px auto 40px auto;
  padding: 3px 0;
  font-family: mr-eaves-sans, "Helvetica", "Helvetica Neue", "San Francisco", sans-serif;
  font-style: italic;
  font-weight: bold;
  font-size: 22px;
  line-height: 1;
}
@media screen and (min-width: 768px) {
  .usp {
    margin: 100px auto;
    width: 80%;
    font-size: 30px;
    line-height: 53px;
  }
}
.usp ul {
  background: #fff;
  color: #000;
  margin: 0;
  padding: 10px 20px;
}
@media screen and (min-width: 768px) {
  .usp ul {
    padding: 20px 50px;
  }
}
.usp li {
  margin: 0;
  padding: 10px 5px;
  line-height: 1.2;
}
@media screen and (min-width: 768px) {
  .usp li {
    padding: 10px 20px;
  }
}
.usp b,
.usp strong {
  font-family: mr-eaves-sans, "Helvetica", "Helvetica Neue", "San Francisco", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.reference-wrapper {
  padding-top: 0;
}
@media screen and (min-width: 768px) {
  .reference-wrapper {
    padding-top: 10px;
  }
}
.reference-wrapper .contents {
  border-top: 3px solid #6f41d1;
  border-bottom: 3px solid #6f41d1;
  padding: 30px 30px 10px 30px;
}
@media screen and (min-width: 768px) {
  .reference-wrapper .contents {
    padding: 30px;
  }
}
.reference-wrapper .reference {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px 0;
  /*
  > div:first-child {
      @include respond-to($tablet) {
          grid-column: span 2;
      }
  }
  */
}
@media screen and (min-width: 768px) {
  .reference-wrapper .reference {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
  }
}
.reference-wrapper .reference img {
  float: right;
  margin: 5px 0 10px 10px;
  max-width: 70px;
}
@media screen and (min-width: 768px) {
  .reference-wrapper .reference img {
    float: left;
    margin: 0 30px 0 0;
    max-width: 130px;
  }
}
.reference-wrapper .reference em {
  position: relative;
  font-family: mr-eaves-sans, "Helvetica", "Helvetica Neue", "San Francisco", sans-serif;
  font-size: 1.3em;
  line-height: 1;
  font-style: normal;
}
.reference-wrapper .reference em::before {
  content: "”";
  position: absolute;
  top: 2px;
  left: -8px;
}
.reference-wrapper .reference em span {
  display: none;
}
.reference-wrapper .reference strong {
  display: inline-block;
  margin-top: 10px;
  font-weight: 500;
  font-size: 0.8em;
}

.price-table {
  margin: 0 auto;
  font-size: 1.25em;
}
.price-table .price {
  color: #55aa4d;
}
.price-table th {
  font-family: sofia-pro-soft, "Helvetica", "Helvetica Neue", "San Francisco", sans-serif;
  font-size: 0.85em;
}

.icon-list {
  margin: 2em 0 0 0;
  padding: 0;
  list-style-type: none;
}
.icon-list li {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 0 0 2em 0;
}
.icon-list li .icon {
  width: 32px;
  height: 32px;
  margin: 5px 20px 0 0;
}
.icon-list li div {
  flex: 1;
}

.please-note {
  font-size: 0.85em;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .please-note {
    text-align: right;
  }
}

.tips {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #6f41d1;
  background: linear-gradient(156deg, #6f41d1 0%, #4194d1 53%, #41d1c0 100%);
  color: #fff;
  margin: 20px 0;
  padding: 20px 20px 40px 20px;
  box-shadow: 0px 4px 8px rgba(66, 153, 208, 0.6);
}
@media screen and (min-width: 768px) {
  .tips {
    flex-direction: row;
    margin: 50px 0;
    padding: 40px 70px 40px 50px;
  }
}
.tips .icon {
  float: left;
  margin: 10px 0 20px 0;
}
@media screen and (min-width: 768px) {
  .tips .icon {
    width: 80px;
    margin: 0 30px 5px 0;
  }
}
.tips .icon img {
  width: 32px;
  height: 32px;
}
@media screen and (min-width: 768px) {
  .tips .icon img {
    width: 64px;
    height: 64px;
    margin: 0 30px 5px 0;
  }
}
.tips .text {
  flex: 1;
}
.tips h2,
.tips h3 {
  margin: 0 0 10px 0;
  padding: 0;
}
.tips ol {
  font-size: 0.9em;
  padding: 30px 0 0 20px;
}
.tips li {
  margin: 0 0 0.5em 0;
  padding: 0;
}
.tips p {
  margin: 0;
  padding: 0;
  font-size: 0.9em;
  line-height: 1.6;
}
.tips a {
  color: #fff;
}
.tips .button-wrapper {
  margin: 40px 0 0 0;
}
@media screen and (min-width: 1024px) {
  .tips .button-wrapper {
    margin: 0 0 0 30px;
  }
}
.tips .button-wrapper .button {
  margin: 0;
}

.alert {
  margin: 0 0 1em 0;
}
.alert p {
  margin: 0;
  font-family: sofia-pro-soft, "Helvetica", "Helvetica Neue", "San Francisco", sans-serif;
  font-weight: bold;
}
.alert.alert-notice {
  padding: 25px 40px;
  margin: 0 0 2em 0;
  background: linear-gradient(156deg, #fbf5e4 0%, #fff5d6 53%, #fbeac9 100%);
  color: #000;
  border-radius: 5px;
}
.alert.alert-notice p {
  font-weight: 400;
}
.alert.alert-notice span {
  font-size: 0.85em;
}
.alert.alert-error {
  color: #ba2c2c;
}
.alert.alert-error span {
  color: #000;
  font-size: 0.85em;
  font-weight: 400;
}
.alert.alert-error a {
  color: #ba2c2c;
}

.login {
  max-width: 800px;
  margin: 30px auto 50px auto;
}
@media screen and (min-width: 768px) {
  .login {
    margin: 0 auto 100px auto;
  }
}
.login .login-box {
  background: #f5f5f5;
  border-radius: 10px;
  padding: 20px;
}
@media screen and (min-width: 768px) {
  .login .login-box {
    padding: 50px 100px 70px 100px;
  }
}
.login .login-box h1 {
  font-family: sofia-pro-soft, "Helvetica", "Helvetica Neue", "San Francisco", sans-serif;
  font-size: 1.3em;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  margin: 0 0 20px 0;
}
@media screen and (min-width: 768px) {
  .login .login-box h1 {
    font-family: mr-eaves-sans, "Helvetica", "Helvetica Neue", "San Francisco", sans-serif;
    font-style: normal;
    font-weight: 100;
    text-align: left;
    font-size: 4em;
    text-align: left;
  }
}
.login .login-box .login-form,
.login .login-box .reset-password-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}
.login .login-box .login-form .width-100,
.login .login-box .reset-password-form .width-100 {
  width: 100%;
}
.login .login-box .login-form a,
.login .login-box .reset-password-form a {
  color: #4194d1;
  font-size: 12px;
}
.login .login-box .login-form label,
.login .login-box .reset-password-form label {
  width: 100%;
  text-align: left;
  margin: 0;
}
.login .login-box .login-form input[type=email],
.login .login-box .reset-password-form input[type=email] {
  margin: 0 0 10px 0;
}
.login .login-box .login-form input[type=password],
.login .login-box .login-form input[type=text],
.login .login-box .reset-password-form input[type=password],
.login .login-box .reset-password-form input[type=text] {
  margin: 0 0 20px 0;
}

.control-group {
  margin: 0 0 0.5em 0;
}
@media screen and (min-width: 768px) {
  .control-group {
    margin: 0 0 1.5em 0;
  }
}
.control-group .info {
  margin: 1.5em 0 0.5em 0;
}
.control-group .info em {
  font-style: normal;
  font-size: 0.85em;
  display: inline-block;
  background: #fbfbda;
  color: #000;
  padding: 7px 10px;
}
.control-group.error input {
  border-color: #ba2c2c;
}

@supports (display: flex) {
  .col-2 {
    display: flex;
    justify-content: space-between;
  }
  .col-2 .control-group {
    width: 48%;
  }
}
input.span2 {
  width: 135px;
}
@media screen and (min-width: 768px) {
  input.span2 {
    width: 300px;
  }
}

.form-address {
  border: 3px solid #6f41d1;
  margin: 30px 0 0 0;
  padding: 5px 30px 20px 30px;
}
@media screen and (min-width: 768px) {
  .form-address {
    margin: 50px 0 0 0;
    max-width: 400px;
  }
}
.form-address .col-2 .control-group:first-child {
  width: 35%;
}
.form-address .col-2 .control-group:last-child {
  width: 63%;
}

.form-actions {
  margin: 40px 0;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .form-actions {
    margin: 4em 30px 0 auto;
  }
}

.illustration-start-1 {
  shape-outside: circle(50%);
  float: right;
  max-width: 30%;
  margin: 12px 0 30px 10px;
}
@media screen and (min-width: 340px) {
  .illustration-start-1 {
    max-width: 40%;
    margin: 20px 0 30px 10px;
  }
}
@media screen and (min-width: 768px) {
  .illustration-start-1 {
    float: right;
    max-width: 400px;
    margin: -40px -20px 60px 40px;
  }
}
@media screen and (min-width: 1024px) {
  .illustration-start-1 {
    float: right;
    max-width: 500px;
    margin: -40px -25px 60px 70px;
  }
}

.screenshot-start-1 {
  max-width: 90%;
  margin: 1em 5%;
}
@media screen and (min-width: 768px) {
  .screenshot-start-1 {
    float: right;
    max-width: 350px;
    margin: 10px 30px;
  }
}

.illustration-start-2 {
  shape-outside: circle(50%);
  float: right;
  max-width: 30%;
  margin: 12px 0 30px 10px;
}
@media screen and (min-width: 340px) {
  .illustration-start-2 {
    float: none;
    max-width: 80%;
    margin: 0 10%;
  }
}
@media screen and (min-width: 768px) {
  .illustration-start-2 {
    float: right;
    max-width: 100%;
    margin: -40px 0 60px 20px;
  }
}

.screenshot-om-1 {
  max-width: 90%;
  margin: 1em 5%;
}
@media screen and (min-width: 768px) {
  .screenshot-om-1 {
    float: right;
    max-width: 350px;
    margin: 10px 30px;
  }
}

.illustration-om-1 {
  shape-outside: circle(50%);
  float: right;
  max-width: 30%;
  margin: 12px 0 30px 10px;
}
@media screen and (min-width: 340px) {
  .illustration-om-1 {
    float: none;
    max-width: 85%;
    margin: 0 7.5% 10px 7.5%;
  }
}
@media screen and (min-width: 768px) {
  .illustration-om-1 {
    float: right;
    width: 30%;
    margin: -30px 50px 60px 150px;
  }
}

.illustration-om-2 {
  clear: both;
  shape-outside: circle(50%);
  float: right;
  max-width: 30%;
  margin: 12px 0 30px 10px;
}
@media screen and (min-width: 340px) {
  .illustration-om-2 {
    float: none;
    max-width: 85%;
    margin: 0 7.5% 10px 7.5%;
  }
}
@media screen and (min-width: 768px) {
  .illustration-om-2 {
    float: right;
    width: 30%;
    margin: 0 50px 60px 150px;
  }
}

.illustration-pris-1 {
  shape-outside: circle(50%);
  float: right;
  max-width: 40%;
  margin: 0 0 30px 20px;
}
@media screen and (min-width: 768px) {
  .illustration-pris-1 {
    float: right;
    max-width: 400px;
    margin: -40px -20px 60px 40px;
  }
}
@media screen and (min-width: 1024px) {
  .illustration-pris-1 {
    float: right;
    max-width: 500px;
    margin: -40px -25px 60px 50px;
  }
}

.illustration-kontakt-1 {
  shape-outside: circle(50%);
  float: right;
  max-width: 40%;
  margin: 0 0 30px 20px;
}
@media screen and (min-width: 768px) {
  .illustration-kontakt-1 {
    float: right;
    max-width: 400px;
    margin: -40px -20px 60px 40px;
  }
}
@media screen and (min-width: 1024px) {
  .illustration-kontakt-1 {
    margin: -40px -25px 60px 50px;
  }
}

@media screen and (min-width: 600px) {
  .sticky-footer {
    display: grid;
    grid-template-rows: auto 1fr auto;
    height: 100vh;
  }
}

footer {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 40px 9.7883597884% 60px 9.7883597884%;
  margin: 0 auto;
  background: linear-gradient(156deg, #6f41d1 0%, #4194d1 53%, #41d1c0 100%);
  color: #fff;
  text-align: center;
}
@media screen and (min-width: 768px) {
  footer {
    padding: 80px 13.0044843049% 100px 13.0044843049%;
  }
}
footer .footer-logo {
  max-width: 200px;
}
@media screen and (min-width: 768px) {
  footer .footer-logo {
    max-width: 300px;
  }
}
footer p {
  margin: 0;
  padding: 0;
  font-size: 0.6em;
}
footer a {
  color: #fff;
}
footer span {
  opacity: 0.3;
}
footer ul {
  margin: 10px 0 30px 0;
  list-style-type: none;
}
@media screen and (min-width: 768px) {
  footer ul {
    margin: 30px 0;
  }
}
footer ul li {
  padding: 7px 10px;
}
@media screen and (min-width: 768px) {
  footer ul li {
    padding: 10px;
    display: inline;
  }
}
footer ul a {
  text-decoration: none;
  transition: 1s all;
}
footer ul a:hover {
  color: rgba(255, 255, 255, 0.5);
}
footer ul .current a {
  text-decoration: underline;
}

.button {
  border: 2px solid #4194d1;
  background: #fff;
  color: #000;
  padding: 10px 28px 10px 28px;
  cursor: pointer;
  -webkit-appearance: none;
  font-family: sofia-pro, "Helvetica", "Helvetica Neue", "San Francisco", sans-serif;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 20px;
  text-decoration: none;
  white-space: nowrap;
  transition: all 0.3s;
  line-height: 1.5;
  /* Primary action button, i.e. 'Ok' or 'Send' */
  /* Secondary action button, i.e. 'Cancel' */
  /* White on transparent */
  /* Black on transparent */
  /* Transparent */
}
.button:hover {
  background-color: #4194d1;
  border: 2px solid #4194d1;
  color: #fff;
}
.button:active {
  background-color: #56a0d6;
  border: 2px solid #4194d1;
  color: #fff;
}
.button:focus {
  outline: none;
  border: 1px solid rgba(66, 153, 208, 0.6);
}
.button.disabled, .button.disabled:hover {
  background: #eee;
  border-color: #eee;
  color: #aaa;
}
.button.disabled .icon, .button.disabled:hover .icon {
  opacity: 0.5;
}
.button .icon {
  float: left;
  display: inline-block;
  width: 24px;
  height: 24px;
  background-size: 100%;
  margin: -2px 7px 0 -4px;
}
.button--primary, .button--submit {
  background: linear-gradient(90deg, #4194d1, #41d1c0);
  padding: 12px 40px 12px 40px;
  border: 0;
  color: #fff;
  min-width: 80px;
  text-align: center;
  transition: 0.5s all;
}
.button--primary:hover, .button--submit:hover {
  background: linear-gradient(90deg, #3088c8, #30c8b6);
  color: #fff;
  border: 0;
}
.button--primary:active, .button--submit:active {
  outline: none;
  border: 1px solid rgba(66, 153, 208, 0.6);
  background: #784dd4;
  color: #fff;
  border: 0;
}
.button--primary.disabled, .button--primary.disabled:hover, .button--submit.disabled, .button--submit.disabled:hover {
  text-shadow: none;
}
.button--secondary {
  border: 2px solid #000;
  background: #000;
  color: #fff;
}
.button--secondary:hover {
  border-color: #0d0d0d;
  background: #0d0d0d;
  color: #fff;
}
.button--secondary:active {
  border-color: #1a1a1a;
  background: #1a1a1a;
  color: #fff;
}
.button--secondary.disabled, .button--secondary.disabled:hover {
  background: #0d0d0d;
  border-color: #0d0d0d;
  color: #404040;
}
.button--white {
  border: 2px solid #fff;
  background: transparent;
  color: #fff;
}
.button--white:hover {
  border: 2px solid #fff;
  background: #fff;
  color: #000;
}
.button--white:active {
  border: 2px solid #fff;
  background: #fff;
  color: #000;
}
.button--white.disabled, .button--white.disabled:hover {
  border: 2px solid white;
  color: white;
}
.button--black {
  border: 2px solid #000;
  background: transparent;
  color: #000;
}
.button--black:hover {
  border: 2px solid #000;
  background: #000;
  color: #fff;
}
.button--black:active {
  border: 2px solid #000;
  background: #000;
  color: #fff;
}
.button--black.disabled, .button--black.disabled:hover {
  border-color: gray;
  color: gray;
}
.button--transparent {
  display: none;
  background: rgba(255, 255, 255, 0.15);
  color: #fff;
  border: 0;
}
@media screen and (min-width: 768px) {
  .button--transparent {
    display: block;
  }
}
.button--transparent:hover {
  background: rgba(255, 255, 255, 0.25);
  color: #fff;
  border: 0;
}

a.button {
  display: inline-block;
}

.module-prices {
  background: #eff5f9;
  padding: 30px 0;
}
.module-prices .price-options {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 50px 35px;
  align-items: flex-start;
  padding: 0 30px;
}
@media screen and (min-width: 600px) {
  .module-prices .price-options {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (min-width: 768px) {
  .module-prices .price-options {
    grid-gap: 50px 35px;
  }
}
@media screen and (min-width: 1024px) {
  .module-prices .price-options {
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0;
  }
}
.module-prices .price-option {
  background: #fff;
  padding: 24px 0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  text-align: center;
  transition: all 0.2s;
}
.module-prices .price-option:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}
.module-prices .price-option h3 {
  margin: 0;
  font-family: sofia-pro, "Helvetica", "Helvetica Neue", "San Francisco", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;
}
.module-prices .price-option .price {
  margin: 0;
  font-family: mr-eaves-sans, "Helvetica", "Helvetica Neue", "San Francisco", sans-serif;
  font-style: italic;
  font-weight: bold;
  font-size: 50px;
  line-height: 100%;
}
.module-prices .price-option .hr {
  margin: 1em auto 1em auto;
}
.module-prices .price-option ul {
  list-style: none;
  font-size: 0.85em;
}
.module-prices .price-option ul li:first-child {
  font-size: 1.1em;
}
.module-prices .price-option .info {
  padding: 20px;
  font-size: 0.85em;
}
.module-prices .price-info {
  padding: 40px 20px 0 20px;
}
@media screen and (min-width: 768px) {
  .module-prices .price-info {
    padding: 70px 50px 0 50px;
  }
}
@media screen and (min-width: 1024px) {
  .module-prices .price-info {
    padding: 70px 50px 0 50px;
  }
}

.main.guides {
  padding-bottom: 40px;
}

.module-guides {
  background: #eff5f9;
  padding: 30px 0;
}
.module-guides .contents {
  max-width: 1100px;
}
.module-guides .guide-cards {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 50px 35px;
  align-items: stretch;
  padding: 0 30px;
}
@media screen and (min-width: 600px) {
  .module-guides .guide-cards {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (min-width: 768px) {
  .module-guides .guide-cards {
    grid-gap: 50px 35px;
  }
}
@media screen and (min-width: 1024px) {
  .module-guides .guide-cards {
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0;
  }
}
.module-guides .guide-card {
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  align-items: center;
  background: #fff;
  padding: 24px 0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  text-align: center;
  transition: box-shadow 0.2s;
}
.module-guides .guide-card:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}
.module-guides .guide-card .image {
  aspect-ratio: 16/9;
  width: 100%;
}
.module-guides .guide-card h2 {
  margin: 1em 20px 0 20px;
  font-family: sofia-pro-soft, "Helvetica", "Helvetica Neue", "San Francisco", sans-serif;
  font-style: normal;
  line-height: 1.2;
  font-size: 22px;
}
.module-guides .guide-card h2 a {
  color: #333;
  text-decoration: none;
}
.module-guides .guide-card h2 a:hover {
  color: #000;
}
.module-guides .guide-card .info {
  padding: 20px;
  font-size: 0.85em;
}
.module-guides .guide-card .action {
  margin-top: auto;
}

@media print {
  body > *:not(main) {
    display: none;
  }
}