// ============================================================================
// Module: Prices
// ============================================================================

// Prices
//
// Pricing section of public site.
//
// :hover           - hover state
// :focus           - focus state
// :active          - active state
//
// Markup:
// <div class="module-prices">
// <div class="{{modifier_class}}">Test</div>
// <div class="{{modifier_class}}">Test</div>
// <div class="{{modifier_class}}">Test</div>
// <div class="{{modifier_class}}">Test</div>
// </div>
//
// Styleguide 4.2

.module-prices {
    background: #eff5f9;
    padding: 30px 0;

    .price-options {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-gap: 50px 35px;
        align-items: flex-start;
        padding: 0 30px;

        @include respond-to($large-phone) {
            grid-template-columns: 1fr 1fr;
        }

        @include respond-to($tablet) {
            grid-gap: 50px 35px;
        }

        @include respond-to($desktop) {
            grid-template-columns: 1fr 1fr 1fr;
            padding: 0;
        }
    }

    .price-option {
        background: #fff;
        padding: 24px 0;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        text-align: center;
        transition: all 0.2s;

        &:hover {
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
        }

        h3 {
            margin: 0;
            font-family: $font-base;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            text-transform: uppercase;
        }

        .price {
            margin: 0;
            font-family: $font-complement;
            font-style: italic;
            font-weight: bold;
            font-size: 50px;
            line-height: 100%;
        }

        .hr {
            margin: 1em auto 1em auto;
        }

        ul {
            list-style: none;
            font-size: 0.85em;

            li:first-child {
                font-size: 1.1em;
            }
        }

        .info {
            padding: 20px;
            font-size: 0.85em;
        }
    }

    .price-info {
        padding: 40px 20px 0 20px;

        @include respond-to($tablet) {
            padding: 70px 50px 0 50px;
        }

        @include respond-to($desktop) {
            padding: 70px 50px 0 50px;
        }
    }
}
