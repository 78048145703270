// ============================================================================
// Base
// ============================================================================

html {
    @include font-smoothing(on);

    font-size: 17px;
    font-family: $font-base;

    @include respond-to($large-phone) {
        font-size: 17px;
    }

    @include respond-to($desktop) {
        font-size: 18px;
    }
}

body {
    background: $color-1;
    color: $color-text;
}

/* Remove ~300 ms dealy on Mobile Safari for the following elements */
a,
button,
input,
select,
textarea,
label,
summary {
    touch-action: manipulation;
}

// ============================================================================
// Typography
// ============================================================================

h1,
h2 {
    font-family: $font-complement;
    font-weight: 200;
}

h3,
h4,
h5,
h6 {
    font-family: $font-complement;
    font-weight: 700;
}


p,
ul,
li {
    font-weight: 400;
}

p,
ul,
ol,
li,
h1,
h2,
h3,
h4 {
    margin: 0 0 1.45em 0;
    padding: 0;
    font-size: 1em;
    line-height: 1.45;

    @include respond-to($tablet) {
        margin: 0 0 1em 0;
        line-height: 1.55;
    }
}

ul ul,
ol ol,
dl dl {
    margin-bottom: 0;
}

h1 {
    margin: 0 0 0.5em 0;
    font-size: 2em;
    line-height: 1;
    font-weight: 100;
    font-style: italic;

    @include respond-to($phone) {
        margin: 0 0 0.75em 0;
        font-size: 2.5em;
    }

    @include respond-to($large-phone) {
        margin: 0 0 0.75em 0;
        font-size: 5em;
    }
}

h2 {
    font-family: $font-complement-bold;
    margin: 1.5em 0 0.3em 0; // 2 * line height in total
    padding: 0.3em 0;
    font-size: 1.2em;
    line-height: 1.4; // line height / font size

    @include respond-to($large-phone) {
        margin: 1.8em 0 0.2em 0;
        font-size: 40px;
        line-height: 1.2;
    }
}

h3 {
    font-family: $font-complement-bold;
    margin: 2em 0 0.5em 0;
    font-size: 1.3em;
}

h4,
h5,
h6 {
    margin: 1em 0 0.5em 0;
    font-size: 0.9em;
    color: #666;
}

img {
    max-width: 100%;
    height: auto;
}

ul,
ol,
dl {
    margin: 0 0 1.5em 20px;
    line-height: 1.5;

    @include respond-to($tablet) {
        margin: 0 0 1.5em 0;
    }
}

li {
    margin-bottom: 0;
}

dt {
    font-weight: bold;
    color: $color-heading;
}

dd {
    margin: 0 0 1em 0;
}

a {
    color: $color-link;
    font-weight: 600;

    &:hover {
        color: $color-hover;
    }
}

b,
strong {
    font-family: $font-base;
    font-weight: 800;
}

pre {
    padding: 20px;
    background: #eee;
    color: #333;
    font-size: 0.75em;
    line-height: 1.6;
    margin-bottom: 3em;
}


// Subscripts
sup {
    font-feature-settings: "sups" 1;

    @supports (font-variant-position: super) {
        font-feature-settings: normal;
        font-variant-position: super;
    }

    @supports ((font-variant-position: super) or (font-feature-settings: "sups" 1)) {
        vertical-align: inherit;
        font-size: inherit;
    }
}

// Subscripts
sub {
    font-feature-settings: "subs" 1;

    @supports (font-variant-position: sub) {
        font-feature-settings: normal;
        font-variant-position: sub;
    }

    @supports ((font-variant-position: sub) or (font-feature-settings: "subs" 1)) {
        vertical-align: inherit;
        font-size: inherit;
    }
}


// ============================================================================
// Tables
// ============================================================================

table {
    margin: 1.5em 0;
    border-collapse: collapse;
    caption-side: bottom;

    & .number {
        text-align: right;
    }

    //noinspection ALL
    & .alignment {
        text-align: "." center;
    }
}

caption {
    padding: 0.5em 5px 1.5em 5px;
    color: #666;
    font-size: 0.8em;
    font-style: italic;
}

th {
    font-variant-numeric: lining-nums tabular-nums;
    font-weight: bold;
    text-align: left;
    padding: 0.25em 2em 0.25em 0.5em;
    font-size: 0.8em;
    color: #333;
    border-bottom: 1px solid #000;

    @include respond-to($tablet) {
        font-size: 1em;
        padding: 0.25em 2em 0.25em 0.5em;
    }

    &:last-child {
        padding-right: 0.5em;
    }
}

tfoot th {
    text-align: left;
}

td {
    font-variant-numeric: lining-nums tabular-nums;
    text-align: left;
    padding: 0.25em 2em 0.25em 0.5em;
    font-size: 0.8em;
    color: #333;
    border: 0;
    // border-bottom: 1px solid #ddd;

    tr.no-border & {
        border: 0;
    }

    @include respond-to($tablet) {
        font-size: 1em;
        line-height: 1.3;
        padding: 0.25em 2em 0.25em 0.5em;
    }
    @include respond-to($desktop) {
        font-size: 0.9em;
    }

    &:last-child {
        padding-right: 0.5em;
    }
}


// ============================================================================
// General
// ============================================================================

hr {
    margin: 1em 0;
    border: 0;
    border-top: 1px solid #eee;

    @include respond-to($large-phone) {
        margin: 4em 0 3em 0;
    }
}

.hr hr {
    display: none;
}

.hr {
    clear: both;
    background: $color-gradient;
    height: 3px;
    width: 50%;
    margin: 1em auto;

    @include respond-to($large-phone) {
        width: 30%;
        margin: 6em auto 1em auto;
    }
}

blockquote {
    margin: 2em 0 2em 25px;
    color: $color-text;
    font-style: italic;

    @include respond-to($tablet) {
        margin: 0 0 1em 50px;
        padding: 0 20px;
    }

    p {
        quotes: '“' '”' '‘' '’';

        &::before {
            content: open-quote;
            margin-left: -0.83ch;
        }

        &::after {
            content: close-quote;
        }
    }
}

figure {
    margin: 0;
}

figcaption {
    color: #666;
    font-size: 0.8em;
    font-style: italic;
    margin: 0.5em 0 2em 0;
}

object {
    outline: none;
}

.small {
    float: right;
    margin-right: 20px;
    font-size: 11px;
}

.center {
    text-align: center;
}

picture.right {
    float: right;
    margin: 0 0 2em 4em;
}

picture.left {
    float: left;
    margin: 0 2em 4em 0;
}

// ============================================================================
// Forms
// ============================================================================

fieldset {
    margin: 0 0 3em 0;
    padding: 1em;
    border: 1px solid $color-text;

    @include respond-to($large-phone) {
        padding: 1.5em;
    }
}

legend {
    padding: 0 2px;
    color: $color-text;
    font-family: $font-complement-bold;
    font-weight: normal;
}

label {
    font-family: $font-complement-bold;
    font-weight: normal;
    display: block;
    margin: 13px 0 5px 0;
    font-size: 0.85em;

    &.long {
        margin: 15px 0;
        line-height: 0.9;
    }
}

input {
    font-family: $font-base;
    font-weight: normal;
    color: #333;
    font-size: 16px;
    border-radius: 3px;

    &[type="text"],
    &[type="email"],
    &[type="password"],
    &[type="number"],
    &[type="tel"],
    &[type="url"],
    &[type="search"] {
        -webkit-appearance: none;
        box-sizing: border-box;
        margin: 0;
        padding: 7px;
        border: 1px solid #ddd;
        width: 100%;
        background-color: #fff;
    }

    &[type="number"] {
        text-align: center;
        padding: 5px 3px;
        width: 45px;

        @include respond-to($tablet) {
            padding: 5px;
            width: 60px;
        }
    }

    &[type="color"] {
        padding: 0;
    }

    &[type="file"] {
        font-size: 12px;
    }

    &[readonly="readonly"] {
        color: #ccc;
    }

    &[disabled] {
        background: #eee;
        color: #333;
    }

    &:focus:not([readonly]) {
        @include outline();
    }

    &.submit {
        margin: 25px 0 10px 0;
    }
}

textarea {
    font-family: $font-base;
    box-sizing: border-box;
    width: 100%;
    height: 100px;
    padding: 5px;
    border: 1px solid #f5f5f5;
    font-size: 16px;

    @include respond-to($large-phone) {
        max-width: 600px;
    }

    &:focus {
        @include outline();
        // -webkit-transition: all 0.6s ease-in-out;
        // -moz-transition: all 0.6s ease-in-out;
        // -o-transition: all 0.6s ease-in-out;
        // transition: all 0.6s ease-in-out;
    }

    &:focus:not([readonly]) {
        // background: #fcfddd;
        // height: 150px;
    }
}

select {
    display: block;
    font-size: 16px;
    font-family: sans-serif;
    font-weight: 700;
    color: #444;
    line-height: 1.3;
    padding: 0.6em 1.4em 0.5em 0.8em;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    border: 1px solid #aaa;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
    border-radius: 0.5em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'), linear-gradient(to bottom, #fff 0%, #e5e5e5 100%);
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 0.65em auto, 100%;

    &::-ms-expand {
        display: none;
    }

    &:hover {
        border-color: #888;
    }

    &:focus {
        border-color: #aaa;
        box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
        color: #222;
        outline: none;
    }

    & option {
        font-weight: normal;
    }
}

::-webkit-input-placeholder {
    font-size: 16px;
    color: $color-placeholder;
}

:-moz-placeholder { /* Firefox 18- */
    color: $color-placeholder;
}

::-moz-placeholder { /* Firefox 19+ */
    color: $color-placeholder;
}

:-ms-input-placeholder {
    color: $color-placeholder;
}

// ============================================================================
// Responsive video
// ============================================================================

.video-container {
    position: relative;
    overflow: hidden;
    height: 0;
    padding-bottom: 56.25%;
    margin: 1em 0;
}

.video-container iframe,
.video-container object,
.video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
