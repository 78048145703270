// ============================================================================
// Layout
// ============================================================================

body {
    margin: 0;
    padding: 0;
    background: #fff;
    background-size: cover;

    &.calendar-view {
        background: $color-1 url(/assets/images/structure/bg-calendar.jpg) no-repeat center top;
    }

    &.grid::after {
        border-top: 10px solid #DADE0A;
        content: '';
        position: fixed;
        top: 0;
        z-index: 10000;
        height: 100vh;
        width: 100%;
        background: url(/assets/images/structure/grid-mobile.png) repeat-y center top;
        background-size: 100%;

        @include respond-to($large-phone) {
            border-top: 10px solid #F4C90E;
            content: 'large-phone';
        }

        @include respond-to($tablet) {
            background: url(/assets/images/structure/grid-tablet.png) repeat-y center top;
            background-size: 100%;
            border-top: 10px solid #DB8710;
            content: 'tablet';
        }

        @include respond-to($desktop) {
            background: url(/assets/images/structure/grid-desktop.png) repeat-y center top;
            background-size: 100%;
            border-top: 10px solid #CF000E;
            content: 'desktop';
        }

        @include respond-to($widescreen) {
            background: url(/assets/images/structure/grid-widescreen.png) repeat-y center top;
            background-size: 100%;
            border-top: 10px solid #954A11;
            max-width: $layout-max-width;
            left: 50%;
            margin-left: -630px;
            content: 'widescreen';
        }
    }
}

// Skip to main link
.skip-to-main-content[href="#main"] {
    background: rgba(255, 255, 255, 0.9);
    color: #000;
    position: absolute;
    top: 10px;
    right: 100%;
    padding: 10px;
    margin: 5px;

    &:focus {
        right: auto;
    }
}

// Hidden HTML attribute
[hidden] {
    display: none;
}

// Visually hidden
.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap;
}

// Validation
.validation-messages {
    margin: 50px 0;
    padding: 20px;

    &.hide {
        display: none;
    }

    .alert-error {
        background: rgba(255, 0, 0, 0.6);
        color: #fff;
    }

    p {
        margin: 0;
    }
}

.hidden-on-mobile {
    display: none;

    @include respond-to($tablet) {
        display: inline;
    }
}

.title-long {
    display: none;

    @include respond-to($tablet) {
        display: inline;
    }
}

.title-short {
    @include respond-to($tablet) {
        display: none;
    }
}

// ============================================================================
// Colors
// ============================================================================

.color-1 {
    background: $color-1;
    color: $color-1-text;
}

.color-2 {
    background: $color-2;
    color: $color-2-text;
}

.color-3 {
    background: $color-3;
    color: $color-3-text;
}

.color-4 {
    background: $color-4;
    color: $color-4-text;
}

.color-5 {
    background: $color-5;
    color: $color-5-text;
}

.color-6 {
    background: $color-6;
    color: $color-6-text;
}

// ============================================================================
// Header
// ============================================================================

header {
    .fixed-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 100;

        position: fixed;
        box-sizing: border-box;
        width: 100%;
        padding: 10px;
        box-shadow: 0px 4px 8px rgba(66, 153, 208, 0.6);
        background: $color-gradient;
    }

    .logo {
        display: block;
        position: relative;
        top: 2px;

        width: 65px;
        height: auto;
        padding: 5px;
        line-height: 0;
        margin: 0;

        @include respond-to($phone) {
            top: 3px;
            width: 110px;
        }

        @include respond-to($tablet) {
            top: 5px;
            padding: 10px 10px 10px 40px;
            width: 180px;
        }
    }

    nav {
        margin: 0;

        @include respond-to($tablet) {
            margin: 0 30px 0 0;
        }
    }

    ul {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;

        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
            padding: 5px 8px;

            @include respond-to($tablet) {
                padding: 5px 18px;
            }
        }

        a {
            display: block;
            white-space: nowrap;
            font-family: $font-base;
            font-size: 0.7em;
            color: $color-nav;
            text-decoration: none;
            transition: 0.5s all;

            @include respond-to($tablet) {
                font-size: 0.9em;
            }

            &:hover {
                color: $color-nav-hover;
            }
        }

        .home {
            display: none;

            @include respond-to($desktop) {
                display: inline-block;
            }
        }

        .current a {
            border-bottom: 1px solid #fff;
        }

        .button {
            display: none;
            background: rgba(255, 255, 255, 0.15);
            color: #fff;
            border: 0;

            @include respond-to($tablet) {
                display: block;
            }

            &:hover {
                background: rgba(255, 255, 255, 0.25);
                color: #fff;
                border: 0;
            }
        }

        .current a.button {
            border: 0;
            background: rgba($color-2, 0.4);
            color: #fff;
        }
    }
}

.breadcrumb {
    text-decoration: none;
    color: #eee;
    font-family: sofia-pro, sans-serif;
    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;
    padding-bottom: 10px;
    margin-bottom: 3em;
    border-bottom: 1px solid #eee;

    a {
        text-decoration: none;
        color: $color-1;
        font-family: sofia-pro, sans-serif;
        font-size: 13px;
        font-weight: bold;
        text-transform: uppercase;
    }
}

// ============================================================================
// Main
// ============================================================================

.main {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 70px 20px 10px 20px;

    @include respond-to($tablet) {
        padding: 70px 50px 100px 50px;
    }

    @include respond-to($desktop) {
        padding: 70px 20px 120px 20px;
    }


    background: #fff;
    color: #000;

    .header-box {
        .header-create-account {
            display: flex;
        }
    }
}

.contents {
    max-width: 900px;
    margin: 20px auto 50px auto;

    @include respond-to($tablet) {
        margin: 50px auto;
    }

    .main-lead,
    .introduction {
        font-size: 1.15em;

        p {
            line-height: 1.4;
        }

        @include respond-to($tablet) {
            font-size: 1.5em;
            margin-bottom: 2em;

            p {
                line-height: 1.55;
            }
        }
    }

    .button-wrapper {
        margin: 1em 0;
        text-align: center;

        @include respond-to($tablet) {
            margin: 2em 0;
            text-align: left;
        }

        .button,
        img  {
            margin: 0.5em 20px 0.5em 0;
        }
    }

    .button-wrapper-centered {
        .button,
        img {
            margin: 0.5em 10px;
        }
    }
}

.public-contents {
    width: 90%;
    max-width: 1200px;
    margin: 20px auto;

    @include respond-to($tablet) {
        margin: 50px auto;
    }

    .login {
        max-width: initial;
    }

    h1 {
        margin-bottom: 40px;
    }

    .form-field-group.users {
        @include respond-to($large-phone) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 30px;
        }

        @include respond-to($tablet) {
            grid-template-columns: 1fr 1fr 1fr;
        }

        @include respond-to($desktop) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }

        label {
            background-color: #f5f5f5;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 50px;
            padding: 10px;
            box-sizing: border-box;
            border-radius: 5px;

            @include respond-to($large-phone) {
                width: 45%;
                float: left;
                margin: 20px 2.5%;
                min-height: 100px;
                text-align: center;

                @supports (display: grid) {
                    float: none;
                    width: initial;
                    margin: 0;
                }
            }

            @include respond-to($tablet) {
                width: 22%;
                margin: 20px 1.5%;

                @supports (display: grid) {
                    width: initial;
                    margin: 0;
                }
            }

            &:hover {
                background-image: linear-gradient(156deg, #7db2d8 0%, #4194d1 53%, #3080ba 100%);
                color: #fff;
                box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
            }

            input {
                display: none;
            }
        }
    }

    .overlay {
        display: none;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.8);
        z-index: 1000;

        .overlay-content {
            position: fixed;
            width: 80%;
            left: 10%;
            top: 50%;
            margin-top: -100px;
            box-sizing: border-box;
            padding: 20px;
            background-color: #fff;
            border-radius: 5px;
            border: 1px solid #f5f5f5;

            @include respond-to($large-phone) {
                top: 50%;
                left: 50%;
                width: 400px;
                max-width: 80%;
                margin-left: -200px;
            }

            .close {
                position: absolute;
                right: 0;
                top: 0;
                cursor: pointer;

                &:after {
                    content: '×';
                    padding: 10px;
                    font-size: 26px;
                }
            }

            label {
                font-weight: bold;
            }

            input[type="text"],
            input[type="password"] {
                font-size: 2em;
                line-height: 1;
                padding: 10px;
            }

            .password-toggle-button {
                background: #eee;
                right: 5px !important;
                top: 15px !important;
            }

            .button {
                margin: 20px auto 15px auto;
            }
        }
    }

    .alert-error {
        background: #f8e7e2;
        padding: 25px 45px;
        margin-bottom: 30px;
    }
}

.usp {
    background: $color-1;
    background: $color-gradient;
    color: #fff;
    margin: 20px auto 40px auto;
    padding: 3px 0;

    font-family: $font-complement;
    font-style: italic;
    font-weight: bold;
    font-size: 22px;
    line-height: 1;

    @include respond-to($tablet) {
        margin: 100px auto;
        width: 80%;
        font-size: 30px;
        line-height: 53px;
    }

    ul {
        background: #fff;
        color: #000;
        margin: 0;
        padding: 10px 20px;

        @include respond-to($tablet) {
            padding: 20px 50px;
        }
    }

    li {
        margin: 0;
        padding: 10px 5px;
        line-height: 1.2;

        @include respond-to($tablet) {
            padding: 10px 20px;
        }
    }

    b,
    strong {
        font-family: $font-complement;
        font-weight: 800;
        font-style: italic;
    }
}

.reference-wrapper {
    padding-top: 0;

    @include respond-to($tablet) {
        padding-top: 10px;
    }

    .contents {
        border-top: 3px solid $color-1;
        border-bottom: 3px solid $color-1;
        padding: 30px 30px 10px 30px;

        @include respond-to($tablet) {
            padding: 30px;
        }
    }

    .reference {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 20px 0;

        @include respond-to($tablet) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 50px;
        }

        /*
        > div:first-child {
            @include respond-to($tablet) {
                grid-column: span 2;
            }
        }
        */

        img {
            float: right;
            margin: 5px 0 10px 10px;
            max-width: 70px;

            @include respond-to($tablet) {
                float: left;
                margin: 0 30px 0 0;
                max-width: 130px;
            }
        }

        em {
            position: relative;
            font-family: $font-complement;
            font-size: 1.3em;
            line-height: 1;
            font-style: normal;

            &::before {
                content: '”';
                position: absolute;
                top: 2px;
                left: -8px;
            }

            span {
                display: none;
            }
        }

        strong {
            display: inline-block;
            margin-top: 10px;
            font-weight: 500;
            font-size: 0.8em;
        }
    }
}

.price-table {
    margin: 0 auto;
    font-size: 1.25em;

    .price {
        color: #55aa4d;
    }

    th {
        font-family: $font-complement-bold;
        font-size: 0.85em;
    }
}

.icon-list {
    margin: 2em 0 0 0;
    padding: 0;
    list-style-type: none;

    li {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin: 0 0 2em 0;

        .icon {
            width: 32px;
            height: 32px;
            margin: 5px 20px 0 0;
        }

        div {
            flex: 1;
        }
    }
}

.please-note {
    font-size: 0.85em;
    text-align: center;

    @include respond-to($tablet) {
        text-align: right;
    }
}

.tips {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    background: $color-1;
    background: $color-gradient;
    color: #fff;
    margin: 20px 0;
    padding: 20px 20px 40px 20px;
    box-shadow: 0px 4px 8px rgba(66, 153, 208, 0.6);

    @include respond-to($tablet) {
        flex-direction: row;
        margin: 50px 0;
        padding: 40px 70px 40px 50px;
    }

    .icon {
        float: left;
        margin: 10px 0 20px 0;

        @include respond-to($tablet) {
            width: 80px;
            margin: 0 30px 5px 0;
        }

        img {
            width: 32px;
            height: 32px;

            @include respond-to($tablet) {
                width: 64px;
                height: 64px;
                margin: 0 30px 5px 0;
            }
        }
    }

    .text {
        flex: 1;
    }

    h2,
    h3 {
        margin: 0 0 10px 0;
        padding: 0;
    }

    ol {
        font-size: 0.9em;
        padding: 30px 0 0 20px;
    }

    li {
        margin: 0 0 0.5em 0;
        padding: 0;
    }

    p {
        margin: 0;
        padding: 0;
        font-size: 0.9em;
        line-height: 1.6;
    }

    a {
        color: #fff;
    }

    .button-wrapper {
        margin: 40px 0 0 0;

        @include respond-to($desktop) {
            margin: 0 0 0 30px;
        }

        .button {
            margin: 0;
        }
    }
}

.alert {
    margin: 0 0 1em 0;

    p {
        margin: 0;
        font-family: $font-complement-bold;
        font-weight: bold;
    }

    &.alert-notice {
        padding: 25px 40px;
        margin: 0 0 2em 0;
        background: linear-gradient(156deg, #fbf5e4 0%, #fff5d6 53%, #fbeac9 100%) ;
        color: #000;
        border-radius: 5px;

        p {
            font-weight: 400;
        }

        span {
            font-size: 0.85em;
        }
    }

    &.alert-error {
       color: $color-error;

        span {
            color: #000;
            font-size: 0.85em;
            font-weight: 400;
        }

        a {
            color: #ba2c2c;
        }
    }
}

.login {
    max-width: 800px;
    margin: 30px auto 50px auto;

    @include respond-to($tablet) {
        margin: 0 auto 100px auto;
    }

    .login-box {
        background: #f5f5f5;
        border-radius: 10px;
        padding: 20px;

        @include respond-to($tablet) {
            padding: 50px 100px 70px 100px;
        }

        h1 {
            font-family: $font-complement-bold;
            font-size: 1.3em;
            font-style: normal;
            font-weight: bold;
            text-align: center;
            margin: 0 0 20px 0;

            @include respond-to($tablet) {
                font-family: $font-complement;
                font-style: normal;
                font-weight: 100;
                text-align: left;

                font-size: 4em;
                text-align: left;
            }
        }

        .login-form,
        .reset-password-form {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;
            flex-wrap: wrap;

            .width-100 {
                width: 100%;
            }

            a {
                color: $color-2;
                font-size: 12px;
            }

            label {
                width: 100%;
                text-align: left;
                margin: 0;
            }

            input[type="email"] {
                margin: 0 0 10px 0;
            }

            input[type="password"],
            input[type="text"] {
                margin: 0 0 20px 0;
            }
        }
    }
}

// ============================================================================
// Create account
// ============================================================================

.control-group {
    margin: 0 0 0.5em 0;

    @include respond-to($tablet) {
        margin: 0 0 1.5em 0;
    }

    .info {
        margin: 1.5em 0 0.5em 0;

        em {
            font-style: normal;
            font-size: 0.85em;
            display: inline-block;
            background: #fbfbda;
            color: #000;
            padding: 7px 10px;
        }
    }

    &.error {
        input {
            border-color: #ba2c2c;
        }
    }
}

@supports (display: flex) {
    .col-2 {
        display: flex;
        justify-content: space-between;

        .control-group {
            width: 48%;
        }
    }
}

input.span2 {
    width: 135px;

    @include respond-to($tablet) {
        width: 300px;
    }
}

.form-address {
    border: 3px solid $color-1;
    margin: 30px 0 0 0;
    padding: 5px 30px 20px 30px;

    @include respond-to($tablet) {
        margin: 50px 0 0 0;
        max-width: 400px;
    }

    .col-2 {
        .control-group:first-child {
            width: 35%;
        }

        .control-group:last-child {
            width: 63%;
        }
    }
}

.form-actions {
    margin: 40px 0;
    text-align: center;

    @include respond-to($tablet) {
        margin: 4em 30px 0 auto;
    }
}

// ============================================================================
// Illustrations
// ============================================================================

.illustration-start-1 {
    shape-outside: circle(50%);
    float: right;
    max-width: 30%;
    margin: 12px 0 30px 10px;

    @include respond-to($phone) {
        max-width: 40%;
        margin: 20px 0 30px 10px;
    }
    @include respond-to($tablet) {
        float: right;
        max-width: 400px;
        margin: -40px -20px 60px 40px;
    }
    @include respond-to($desktop) {
        float: right;
        max-width: 500px;
        margin: -40px -25px 60px 70px;
    }
}

.screenshot-start-1 {
    max-width: 90%;
    margin: 1em 5%;

    @include respond-to($tablet) {
        float: right;
        max-width: 350px;
        margin: 10px 30px
    }
}

.illustration-start-2 {
    shape-outside: circle(50%);
    float: right;
    max-width: 30%;
    margin: 12px 0 30px 10px;

    @include respond-to($phone) {
        float: none;
        max-width: 80%;
        margin: 0 10%;
    }

    @include respond-to($tablet) {
        float: right;
        max-width: 100%;
        margin: -40px 0 60px 20px;
    }
}

.screenshot-om-1 {
    max-width: 90%;
    margin: 1em 5%;

    @include respond-to($tablet) {
        float: right;
        max-width: 350px;
        margin: 10px 30px
    }
}

.illustration-om-1 {
    shape-outside: circle(50%);
    float: right;
    max-width: 30%;
    margin: 12px 0 30px 10px;

    @include respond-to($phone) {
        float: none;
        max-width: 85%;
        margin: 0 7.5% 10px 7.5%;
    }

    @include respond-to($tablet) {
        float: right;
        width: 30%;
        margin: -30px 50px 60px 150px;
    }
}

.illustration-om-2 {
    clear: both;
    shape-outside: circle(50%);
    float: right;
    max-width: 30%;
    margin: 12px 0 30px 10px;

    @include respond-to($phone) {
        float: none;
        max-width: 85%;
        margin: 0 7.5% 10px 7.5%;
    }

    @include respond-to($tablet) {
        float: right;
        width: 30%;
        margin: 0 50px 60px 150px;
    }
}

.illustration-pris-1 {
    shape-outside: circle(50%);
    float: right;
    max-width: 40%;
    margin: 0 0 30px 20px;

    @include respond-to($tablet) {
        float: right;
        max-width: 400px;
        margin: -40px -20px 60px 40px;
    }
    @include respond-to($desktop) {
        float: right;
        max-width: 500px;
        margin: -40px -25px 60px 50px;
    }
}

.illustration-kontakt-1 {
    shape-outside: circle(50%);
    float: right;
    max-width: 40%;
    margin: 0 0 30px 20px;

    @include respond-to($tablet) {
        float: right;
        max-width: 400px;
        margin: -40px -20px 60px 40px;
    }
    @include respond-to($desktop) {
        margin: -40px -25px 60px 50px;
    }
}

// ============================================================================
// Footer
// ============================================================================

.sticky-footer {
    @include respond-to($large-phone) {
        display: grid;
        grid-template-rows: auto 1fr auto;
        height: 100vh;
    }
}

footer {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    padding: 40px cp($grid-mobile-margin + $grid-mobile-column + $grid-mobile-gutter, $grid-mobile-width)
             60px cp($grid-mobile-margin + $grid-mobile-column + $grid-mobile-gutter, $grid-mobile-width);
    margin: 0 auto;
    background: $color-footer-bg;
    color: $color-footer-text;
    text-align: center;

    @include respond-to($tablet) {
        padding: 80px cp($grid-tablet-margin + $grid-tablet-column + $grid-tablet-gutter, $grid-tablet-width)
                100px cp($grid-tablet-margin + $grid-tablet-column + $grid-tablet-gutter, $grid-tablet-width);
    }

    .footer-logo {
        max-width: 200px;

        @include respond-to($tablet) {
            max-width: 300px;
        }
    }

    p {
        margin: 0;
        padding: 0;
        font-size: 0.6em;
    }

    a {
        color: #fff;
    }

    span {
        opacity: 0.3;
    }

    ul {
        margin: 10px 0 30px 0;
        list-style-type: none;

        @include respond-to($tablet) {
            margin: 30px 0;
        }

        li {
            padding: 7px 10px;

            @include respond-to($tablet) {
                padding: 10px;
                display: inline;
            }
        }

        a {
            text-decoration: none;
            transition: 1s all;

            &:hover {
                color: rgba(255, 255, 255, 0.5);
            }
        }

        .current a {
            text-decoration: underline;
        }
    }
}
