// ============================================================================
// Mixins
// ============================================================================

// Respond to
$media-queries: true !default;
$media-query-free-breakpoint: 900px;

@mixin respond-to($min-width, $max-width: false) {
    @if $media-queries {
        @media screen and (min-width: $min-width) {
            @if $max-width {
                @media (max-width: $max-width) {
                    @content;
                }
            } @else {
                @content;
            }
        }
    } @else if $min-width <= $media-query-free-breakpoint and (not $max-width or $max-width and $max-width >= $media-query-free-breakpoint) {
        @content;
    }
}

// Font smoothing
@mixin font-smoothing($value: on) {
    @if $value == on {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    } @else {
        -webkit-font-smoothing: subpixel-antialiased;
        -moz-osx-font-smoothing: auto;
    }
}

// Outline
@mixin outline() {
    outline: none;
    border: 1px solid rgba(66, 153, 208, 0.6);
}

// Vertical rhythm
@mixin vertical-rhythm($r, $g, $b, $a) {
    background-image: linear-gradient(rgba($r, $g, $b, $a) 1px, transparent 1px);
    background-size: 100% 1.5em;
}

// Text Overflow (Element needs to be block or inline-block)
@mixin text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

// White box-shadow
%white-box-shadow {
    background: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}

// ============================================================================
// Typography
// ============================================================================

.vertical-rhythm {
    background-image: linear-gradient(rgba(0, 0, 0, 0.2) 1px, transparent 1px);
    background-size: 100% 1.55em;
}

%no-ligatures,
.no-ligatures {
    font-feature-settings: "liga" 0;
    @supports (font-variant-ligatures: no-common-ligatures) {
        font-feature-settings: normal;
        font-variant-ligatures: no-common-ligatures;
    }
}

%discretionary-ligatures,
.discretionary-ligatures {
    font-feature-settings: "dlig" 1, "hist" 1;
    @supports (font-variant-ligatures: discretionary-ligatures historical-ligatures) {
        font-feature-settings: normal;
        font-variant-ligatures: discretionary-ligatures historical-ligatures;
    }
}

%diagonal-fractions,
.diagonal-fractions {
    @supports (font-variant-numeric: diagonal-fractions) {
        font-feature-settings: normal;
        font-variant-numeric: diagonal-fractions;
    }
}

p.drop-cap::first-letter {
    float: left;
    font-size: 75px;
    margin: 0 0.1em 0 0;
    padding: 0;
    line-height: 65px;
}

.caps,
%small-caps,
.small-caps {
    font-feature-settings: "smcp" 1, "c2sc" 1;
    @supports (font-variant-caps: all-small-caps) {
        font-feature-settings: normal;
        font-variant-caps: all-small-caps;
    }

    letter-spacing: 0.04em;
}

%proportional-nums,
.proportional-nums {
    font-feature-settings: "pnum" 1;
    @supports (font-variant-numeric: proportional-nums) {
        font-feature-settings: normal;
        font-variant-numeric: proportional-nums;
    }
}

%tabular-nums,
.tabular-nums {
    font-feature-settings: "tnum" 1, "zero" 1;
    @supports (font-variant-numeric: tabular-nums) {
        font-feature-settings: normal;
        font-variant-numeric: tabular-nums slashed-zero;
    }
}

%oldstyle-nums,
.oldstyle-nums {
    font-feature-settings: "onum" 1;
    @supports (font-variant-numeric: oldstyle-nums) {
        font-feature-settings: normal;
        font-variant-numeric: oldstyle-nums;
    }
}

%lining-nums,
.lining-nums {
    font-feature-settings: "lnum" 1;
    @supports (font-variant-numeric: lining-nums) {
        font-feature-settings: normal;
        font-variant-numeric: lining-nums;
    }
}

%lining-nums-with-fractions,
.lining-nums-with-fractions {
    font-feature-settings: "lnum" 1, "frac" 1;
    @supports (font-variant-numeric: lining-nums) and (font-variant-numeric: diagonal-fractions) {
        font-feature-settings: normal;
        font-variant-numeric: lining-nums diagonal-fractions;
    }
}

%proportional-lining-nums,
.proportional-lining-nums {
    font-feature-settings: "pnum" 1, "lnum" 1;
    @supports (font-variant-numeric: proportional-nums) {
        font-feature-settings: normal;
        font-variant-numeric: proportional-nums lining-nums;
    }
}

%proportional-oldstyle-nums,
.proportional-oldstyle-nums {
    font-feature-settings: "pnum" 1, "onum" 1;
    @supports (font-variant-numeric: proportional-nums) {
        font-feature-settings: normal;
        font-variant-numeric: proportional-nums oldstyle-nums;
    }
}

%tabular-lining-nums,
.tabular-lining-nums {
    font-feature-settings: "tnum" 1, "lnum" 1;
    @supports (font-variant-numeric: tabular-nums) {
        font-feature-settings: normal;
        font-variant-numeric: tabular-nums lining-nums;
    }
}

%tabular-oldstyle-nums,
.tabular-oldstyle-nums {
    font-feature-settings: "tnum" 1, "onum" 1;
    @supports (font-variant-numeric: tabular-nums) {
        font-feature-settings: normal;
        font-variant-numeric: tabular-nums oldstyle-nums;
    }
}
