@use "sass:math";

// ============================================================================
// Responsive breakpoints
// ============================================================================

$phone: 340px;
$large-phone: 600px;
$tablet: 768px;
$desktop: 1024px;
$widescreen: 1300px;

$layout-max-width: 1260px;
$content-max-width: 1160px;

@function calc-percent($target, $container) {
    @return (math.div($target, $container) * 100%);
}

@function cp($target, $container) {
    @return calc-percent($target, $container);
}

// ============================================================================
// Grid
// ============================================================================

/*
MOBILE (12 COL GRID):      12 + 12 x (50 + 12) +  12  =>   756 PX
TABLET (12 COL GRID):      60 + 12 x (60 + 25) +  60  =>  1115 PX
DESKTOP (12 COL GRID):    100 + 12 x (60 + 40) + 100  =>  1360 PX
WIDESCREEN (12 COL GRID):  50 + 12 x (60 + 40) +  50  =>  1260 PX
*/

$grid-mobile-margin: 12;
$grid-mobile-column: 50;
$grid-mobile-gutter: 12;
$grid-mobile-width: 756;

$grid-tablet-margin: 60;
$grid-tablet-column: 60;
$grid-tablet-gutter: 25;
$grid-tablet-width: 1115;

$grid-desktop-margin: 100;
$grid-desktop-column: 60;
$grid-desktop-gutter: 40;
$grid-desktop-width: 1360;

$grid-widescreen-margin: 50;
$grid-widescreen-column: 60;
$grid-widescreen-gutter: 40;
$grid-widescreen-width: 1260;

// ============================================================================
// Fonts
// ============================================================================

html:not(.wf-active) h1 {
    font-size: 1.85em;
    letter-spacing: -1px;
    line-height: 1.55;

    @media screen and (min-width: 600px) {
        font-size: 3.6em;
        line-height: 1.5;
    }
}

/* Adobe Fonts */
$font-base: sofia-pro, "Helvetica", "Helvetica Neue", "San Francisco", sans-serif;
$font-complement: mr-eaves-sans, "Helvetica", "Helvetica Neue", "San Francisco", sans-serif;
$font-complement-bold: sofia-pro-soft, "Helvetica", "Helvetica Neue", "San Francisco", sans-serif;

.oldstyle-numerals {
    font-feature-settings: "onum" 1, "frac" 1, "calt" 0;
    font-variant-numeric: oldstyle-nums;
}

.lining-numerals {
    font-feature-settings: "lnum" 1, "frac" 1, "calt" 0;
    font-variant-numeric: lining-nums;
}

.proportional-numerals {
    font-feature-settings: "pnum" 1, "frac" 1, "calt" 0;
    font-variant-numeric: proportional-nums;
}

.tabular-numerals {
    font-feature-settings: "tnum" 1, "frac" 0, "calt" 0;
    font-variant-numeric: tabular-nums;
}

$color-0: #fff;
$color-1: #6f41d1;
$color-2: #4194d1;
$color-3: #41d1c0;
$color-4: #000;
$color-5: #000;
$color-6: #000;

$color-0-text: #333;
$color-1-text: #fff;
$color-2-text: #fff;
$color-3-text: #fff;
$color-4-text: #fff;
$color-5-text: #fff;
$color-6-text: #fff;

$color-heading: #000;
$color-heading-hover: $color-4;

$color-text: $color-0-text;
$color-text-light: #999;

$color-link: darken($color-2, 15);
$color-hover: darken($color-2, 5);
$color-active: lighten($color-2, 5);

$color-nav: #fff;
$color-nav-hover: rgba(#fff, 0.7);
$color-nav-active: #fff;

$color-ok: #4d9a3d;
$color-error: #ba2c2c;
$color-placeholder: #888;

$color-mobile-nav: rgba(#fff, 0.85);
$color-mobile-nav-2: #eee;
$color-mobile-nav-3: #ddd;
$color-mobile-nav-text: #333;
$color-mobile-nav-focus: $color-6;

$color-gradient: linear-gradient(156deg, $color-1 0%, $color-2 53%, $color-3 100%);

$color-footer-bg: $color-gradient;
$color-footer-text: #fff;
