// ============================================================================
// Module: Guides
// ============================================================================

// Tips
//
// Guides and tips on the public site.
//
// :hover           - hover state
// :focus           - focus state
// :active          - active state
//
// Markup:
// <div class="module-tips">
// <div class="{{modifier_class}}">Test</div>
// <div class="{{modifier_class}}">Test</div>
// <div class="{{modifier_class}}">Test</div>
// <div class="{{modifier_class}}">Test</div>
// </div>
//
// Styleguide 4.3

.main.guides {
    padding-bottom: 40px;
}

.module-guides {
    background: #eff5f9;
    padding: 30px 0;

    .contents {
        max-width: 1100px;
    }

    .guide-cards {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-gap: 50px 35px;
        align-items: stretch;
        padding: 0 30px;

        @include respond-to($large-phone) {
            grid-template-columns: 1fr 1fr;
        }

        @include respond-to($tablet) {
            grid-gap: 50px 35px;
        }

        @include respond-to($desktop) {
            grid-template-columns: 1fr 1fr 1fr;
            padding: 0;
        }
    }

    .guide-card {
        display: flex;
        flex-direction: column;
        justify-items: flex-start;
        align-items: center;
        background: #fff;
        padding: 24px 0;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        text-align: center;
        transition: box-shadow 0.2s;

        &:hover {
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
        }

        .image {
            aspect-ratio: 16 / 9;
            width: 100%;
        }

        h2 {
            margin: 1em 20px 0 20px;
            font-family: $font-complement-bold;
            font-style: normal;
            line-height: 1.2;
            font-size: 22px;

            a {
                color: #333;
                text-decoration: none;

                &:hover {
                    color: #000;
                }
            }
        }

        .info {
            padding: 20px;
            font-size: 0.85em;
        }

        .action {
            margin-top: auto;
        }
    }
}
